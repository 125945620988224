export const highPrioDaily = {
    id: 'dailyHigh',
    title: 'High Priority Dailies',
    desc: 'Do everything you can to do these things every day.',
    tasks: [
        {
            name: 'Daily Challenges',
            req: null,
            desc: 'Do the 3 daily challenges assigned to you randomly each day. Can be rerolled or extended with Vis Wax. Extended tasks give increased rewards.',
            wikiLink: 'https://runescape.wiki/w/Challenge_System#Daily_challenges',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Shop Run',
            req: null,
            desc: 'Run around the world buying useful things from as many shops as you care to or can afford. Shop restock on daily reset.',
            wikiLink: 'https://runescape.wiki/w/Ironman_Mode/Strategies/Shop_Run',
            wikiText: 'Ironman Useful Shops List'
        },
        {
            name: 'Jack of Trades Aura',
            req: null,
            desc: 'Gain experience in 10, 15, 20 or 25 skills in 3 hours to receive an experience book, depending on aura tier.',
            wikiLink: 'https://runescape.wiki/w/Jack_of_trades_aura/Routines',
            wikiText: 'Recommended Routines'
        },
        {
            name: 'Reaper Task',
            req: {
                dataName: 'combatlevel',
                value: 50
            },
            desc: `Visit Death in Death's Office to get a Reaper (Boss Slayer) assignment. Requires 50 Combat`,
            wikiLink: 'https://runescape.wiki/w/Soul_Reaper#Bosses',
            wikiText: 'Task List'
        },
        {
            name: 'Guthixian Cache',
            req: null,
            desc: `Top of every hour, visit any Divination spring to enter a Guthixian cache. Can earn 200 points per day.`,
            wikiLink: 'https://runescape.wiki/w/Guthixian_Cache',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Player-Owned Farm',
            req: {
                dataName: 'skillvalues',
                values: [
                    {
                        id: 19, //farming
                        level: 17,
                    },
                    {
                        id: 22, //construction
                        level: 20,
                    }
                ],
            },
            desc: `Visit your Player-Owned Farm north of Ardougne. Check beehives, check animals, and send off Adam Antite. Requires 17 Farming and 20 Construction`,
            wikiLink: 'https://runescape.wiki/w/Player-owned_farm/Quick_guide',
            wikiText: 'Quick Guide'
        },
        {
            name: 'Vis Wax',
            req: {
                dataName: 'skillvalues',
                values: [
                    {
                        id: 20, //runecrafting
                        level: 50,
                    },
                ],
            },
            desc: `Get vis wax from the Rune Goldberg Machine. Requires 50 Runecrafting`,
            wikiLink: 'https://runescape.wiki/w/Rune_Goldberg_Machine',
            wikiText: 'Todays Combos'
        },
    ],
}

export const medPrioDaily = {
    id: 'dailyMed',
    title: 'Medium Priority Dailies',
    desc: 'Very beneficial to do every day, but not a big deal to miss.',
    tasks: [
        {
            name: 'Nemi Forest',
            req: null,
            desc: `Navigate the Nemi Forest maze on Mazcab for a good chunk of experience in dungeoneering, farming, mining, and prayer, as well as some goebie rep.
             Much more important and useful in the early game. Solved worlds can be found via the "FC Nemi" friends chat or the NemiForest subreddit.`,
            wikiLink: 'https://old.reddit.com/r/NemiForest/',
            wikiText: 'NemiForest subreddit'
        },
        {
            name: 'Traveling Merchant',
            req: {
                dataName: 'skillvalues',
                values: [
                    {
                        id: 14, //mining
                        level: 81,
                    },
                ],
            },
            desc: `Visit the Traveling Merchant's shop in the Deep Sea Fishing hub. Requires 68 fishing for access to the fishing guild, can be boosted. "Whirlpooldnd" friends chat to find a world it's up in.`,
            wikiLink: 'https://runescape.wiki/w/Travelling_Merchant%27s_Shop',
            wikiText: `Today's Stock`
        },
        {
            name: 'Big Chinchompa',
            req: null,
            desc: `Participate in the Big Chinchompa minigame up to twice a day. Rewards very good hunter xp, and other random garbage.`,
            wikiLink: 'https://runescape.wiki/w/Big_Chinchompa',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Red Sandstone',
            req: {
                dataName: 'skillvalues',
                values: [
                    {
                        id: 14, //mining
                        level: 81,
                    },
                ],
            },
            desc: `Mine red sandstone north of Oo'glog. Additional red sandstone block available south-east of Sophanem after completion of the Desert Elite achievements. 
                    Requires 81 Mining, does not require completion of As a First Resort.`,
            wikiLink: 'https://runescape.wiki/w/Oo%27glog_red_sandstone',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Crystal Sandstone',
            req: {
                dataName: ['skillvalues', 'quests'],
                values: [
                    {
                        id: 14, //mining
                        level: 81,
                    },
                    {
                        questName: `Plague's End`
                    }
                ]
            },
            desc: `Mine crystal sandstone in the Ithell district in Priffdinas. Requires completion of Plague's End and 81 mining. Additional crystal sandstone block available in the Edimmu resource dungeon at 115 Dungeoneering.`,
            wikiLink: 'a',
            wikiText: 'Wiki Link'
        },
    ]
}

export const lowPrioDaily = {
    id: 'dailyLow',
    title: 'Low Priority Dailies',
    desc: 'Not very beneficial, fine to skip at any stage of the game.',
    tasks: [
        {
            name: 'Divine Locations',
            req: null,
            desc: `Gather up to 250 resources from Divine Locations created from Divination per day based on total level, 1 resource per 10 total level. Random daily divine 
            locations can be found in the Gorajo resource dungeon in Prifddinas, requiring 95 dungeoneering and completion of Plague's End. Rewards xp and resources scaled to the divine location.`,
            wikiLink: 'https://runescape.wiki/w/Divine_location',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Free Gorajo Card',
            req: {
                dataName: ['skillvalues', 'quests'],
                values: [
                    {
                        id: 24, //dungeoneering
                        level: 95,
                    },
                    {
                        questName: `Plague's End`
                    }
                ]
            },
            desc: `Collect a free Gorajo card from the Gorajo resource dungeon in Prifddinas. Requires 95 dungeoneering and completion of Plague's End.`,
            wikiLink: 'https://runescape.wiki/w/Gorajo_Hoardstalker_Dungeon',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Kill Bork',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `The Hunt for Surok (miniquest)`
                    }
                ]
            },
            desc: `Kill Bork in the Chaos Tunnels. Rewards a few charms and some slayer experience. Requires completion of The Hunt for Surok. Rewards improve after the completion of the Hard Varrock achievements, and The Mighty Fall quest.`,
            wikiLink: 'https://runescape.wiki/w/Bork',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Kill Phoenix',
            req: {
                dataName: ['skillvalues', 'quests'],
                values: [
                    {
                        id: 18, //slayer
                        level: 51,
                    },
                    {
                        questName: `In Pyre Need`
                    }
                ]
            },
            desc: `Kill Phoenix in its lair. Rewards some firemaking, fletching, crafting, and slayer experience, and 5 phoenix quills. Also has a chance to reward a pet. Requires 51 slayer and completion of In Pyre Need.`,
            wikiLink: 'https://runescape.wiki/w/Phoenix',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Jade Vine',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `Back to my Roots`
                    }
                ]
            },
            desc: `Slay or prune the Jade vine grown in front of the Handelmort Mansion in Ardougne. Rewards meager slayer or farming experience. Requires completion of Back to my Roots.`,
            wikiLink: 'https://runescape.wiki/w/Jade_vine',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Serenity Posts',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `Plague's End`
                    }
                ]
            },
            desc: `Train Agility on the Serenity Posts in Prifddinas. Can be done completely afk, although user input speeds it up greatly. 
            Rewards up to 20000 agility experience per day. Requires completion of Plague's End.`,
            wikiLink: 'https://runescape.wiki/w/Serenity_posts',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Crystal Tree Blossoms',
            req: {
                dataName: ['skillvalues', 'quests'],
                values: [
                    {
                        id: 19, //farming
                        level: 94,
                    },
                    {
                        questName: `Plague's End`
                    }
                ]
            },
            desc: `Harvest 1-2 Crystal tree blossoms from your Crystal Tree planted at the Tower of Voices in Prifddinas. Rewards 15000 farming experience. Requires 94 farming and completion of Plague's End.`,
            wikiLink: 'a',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Buckets of Sand',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `The Hand in the Sand`
                    }
                ]
            },
            desc: `Claim 84 buckets of sand from Bert in Yanille, deposited directly to your bank. Requires completion of The Hand in the Sand. Upgrades to 120 buckets of sand after completion of the Ardougne elite tasks.`,
            wikiLink: 'https://runescape.wiki/w/Bert',
            wikiText: 'Wiki Link'
        },
    ]
}

export const diaryDaily = {
    id: 'dailyDiary',
    title: 'Diary Dailies',
    desc: 'Free stuff available each day as a reward for completing Area Task Sets. Unable to pull Area Task completion so figure it out yourself if you can do these or not.',
    tasks: [
        //ardougne
        {
            name: 'Pure Essence',
            req: null,
            desc: `Claim 100 or 150 noted pure essence from Wizard Cromperty in Ardougne. Requires medium or hard Ardougne tasks respectively.`,
            wikiLink: 'https://runescape.wiki/w/Wizard_Cromperty',
            wikiText: 'Wiki Link'
        },
        //morytania
        {
            name: 'Convert bones to bonemeal',
            req: null,
            desc: `Convert 12, 26, or 39 bones of your choice to bonemeal at Robin in Port Phasmatys. Useful for obtaining Wyvern Bonemeal for Super Prayer Potions. Requires medium, hard, and elite Morytania tasks respectively.`,
            wikiLink: 'https://runescape.wiki/w/Robin',
            wikiText: 'Wiki Link'
        },
        //seers
        {
            name: 'Flax',
            req: null,
            desc: `Claim 30, 60, 120, or 200 noted flax from Geoffry in the flax field west of Catherby. Amounts based on tier of Seer's Village tasks completed.`,
            wikiLink: 'https://runescape.wiki/w/Geoffrey',
            wikiText: 'Wiki Link'
        },
        //desert
        {
            name: 'Potato Cactus',
            req: null,
            desc: `Claim 20, 30, or 40 noted potato cacti from the Weird Old Man outside the kalphite lair. Requires medium, hard, and elite Desert tasks respectively.`,
            wikiLink: 'https://runescape.wiki/w/Weird_Old_Man',
            wikiText: 'Wiki Link'
        },
        //karamja
        {
            name: 'Pineapples and Apples',
            req: null,
            desc: `Claim 40 noted pineapples and 40 noted apples from Dell Monti in Brimhaven. Requires completion of the easy and elite Karamja tasks respectively.`,
            wikiLink: 'https://runescape.wiki/w/Dell_Monti',
            wikiText: 'Wiki Link'
        },
        {
            name: '8000 Tokkul',
            req: null,
            desc: `Claim 8000 Tokkul from TzHaar-Hur-Zuh in TzHaar city. Requires completion of the elite Karamja tasks.`,
            wikiLink: 'https://runescape.wiki/w/TzHaar-Hur-Zuh',
            wikiText: 'Wiki Link'
        },

    ]
}

export const minigameDaily = {
    id: 'dailyMinigame',
    title: 'Other Daily Minigames',
    desc: 'Other minigames with daily resets. Does not include some more useful minigames found in other sections.',
    tasks: [
        {
            name: 'Fish Flingers',
            req: null,
            desc: `Claim up to 2 tickets per day to a max of 10 for entries to Fish Flingers. Rewards fishing experience, fishing xp outfit, and other generally less useful things.`,
            wikiLink: 'https://runescape.wiki/w/Fish_Flingers',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Shooting Star',
            req: null,
            desc: `Cash in up to 200 star dust per day. Rewards some mining xp,runes, and coins. Gives a 15 minute buff that gives a chance to mine additional ore.`,
            wikiLink: 'https://runescape.wiki/w/Shooting_Star',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Evil Tree',
            req: null,
            desc: `Kill up to 2 Evil Tree's per day, each tree requiring it's respective woodcutting and firemaking levels. Rewards farming, woodcutting, and firemaking xp, along with general woodcutting loot.`,
            wikiLink: 'https://runescape.wiki/w/Evil_Tree',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Runesphere',
            req: null,
            desc: `Hand in up to 1000 dust to the Runesphere in the Runespan. Rewards 25 runecrafting experience per dust.`,
            wikiLink: 'https://runescape.wiki/w/Runesphere',
            wikiText: 'Wiki Link'
        },
    ]
}
