import React, {useState} from 'react'
import Stack from '@mui/joy/Stack'
import {useSelector} from 'react-redux'
import {
    Checkbox,
    Sheet,
    Typography,
    Link,
    AccordionGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/joy'
import styled from 'styled-components'


const Item = styled(Sheet)`
    text-align: center;
    color: #fff;
    border: 1px solid #333;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
`

const StyledAccordion = styled(Accordion)`
    width: 85%;
`

const StyledAccordionSummary = styled(AccordionSummary)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const DisabledItem = styled(Item)`
    color: #333;
    background-color: #1e0101;
`

const CompletedItem = styled(Item)`
    background-color: #0B0D0E;
`

const CheckContainer = styled.div`
    width: 5%;
`

const CenterJustify = styled.div`
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const StyledTypography = styled(Typography)`
    width: 100%;
`



export const Tasks = (tasksArray) => {

    const [completedTasks, setCompletedTasks] = useState([])
    const [showAll, setShowAll] = useState(true)

    const data = useSelector((state) => state.player.data || [])
    const questData = useSelector((state) => state.player.quests || [])
    const hideCompleted = useSelector((state) => state.player.hideCompleted)
    const hideUnavailable = useSelector((state) => state.player.hideUnavailable)


    const addCompletedTask = (event) => {
        setCompletedTasks([...completedTasks, event.target.name])
    }

    const removeCompletedTask = (event) => {
        setCompletedTasks(completedTasks.filter(i => i !== event.target.name))
    }

    const playerHasReq = (req) => {
        if (!data) return true
        if (!req) return true
        const skillData = data.skillvalues || null
        const quests = questData.quests || null
        if (!skillData || !quests) return false
        if (req.dataName.constructor === Array) {
            let hasReq = true
            req.values.forEach((r) => {
                //if any req is checked that is not met, no need to check the rest of them
                if (hasReq) {
                    if (r.id) {
                        const currReq = skillData.find(i => i.id === r.id)
                        currReq.level >= r.level ? hasReq = true : hasReq = false
                    } else if (r.questName) {
                        const currReq = quests.find(i => i.title === r.questName)
                        currReq.status === 'COMPLETED' ? hasReq = true : hasReq = false
                    }
                }
            })
            return hasReq
        } else if (req.dataName === 'skillvalues') {
            let hasReq = true
            req.values && req.values.forEach((r) => {
                //if any req is checked that is not met, no need to check the rest of them
                if (hasReq) {
                    const currReq = skillData.find(i => i.id === r.id)
                    currReq.level >= r.level ? hasReq = true : hasReq = false
                }
            })
            return hasReq
        } else {
            return data[req.dataName] >= req.value
        }
    }

    const renderTaskSet = (setsArray) => {
        return setsArray.tasksArray.map(set => {
            return (
                <AccordionGroup size='lg' key={set.title} id={set.id}>
                    <StyledAccordion defaultExpanded>
                        <StyledAccordionSummary>
                            <StyledTypography level="h2" fontSize="xl">{set.title}</StyledTypography>
                            <StyledTypography level="body-xs">{set.desc}</StyledTypography>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            {
                                set.tasks.map((d) => {
                                    if (completedTasks.includes(d.name) && !hideCompleted) {
                                        return (
                                            <CompletedItem key={d.name} sx={{backgroundColor: (completedTasks.includes(d.name) ? '#112d00' : '#0B0D0E')}}>
                                                <CheckContainer>
                                                    <Checkbox color="success" onChange={removeCompletedTask} name={d.name} checked={completedTasks.includes(d.name)} />
                                                </CheckContainer>
                                                <CenterJustify>
                                                    <StyledTypography level="h3" fontSize="xl">{d.name}</StyledTypography>
                                                    <StyledTypography level="body-sm">{d.desc}</StyledTypography>
                                                    {d.wikiLink && <Link href={d.wikiLink}>{d.wikiText ? d.wikiText: 'Wiki Link'}</Link>}
                                                </CenterJustify>
                                            </CompletedItem>
                                        )
                                    }
                                    if (playerHasReq(d.req) && !completedTasks.includes(d.name)) {
                                        return (
                                            <Item key={d.name} sx={{backgroundColor: (completedTasks.includes(d.name) ? '#112d00' : '#0B0D0E')}}>
                                                <CheckContainer>
                                                    <Checkbox color="success" onChange={addCompletedTask} name={d.name} checked={completedTasks.includes(d.name)} />
                                                </CheckContainer>
                                                <CenterJustify>
                                                    <StyledTypography level="h3" fontSize="xl">{d.name}</StyledTypography>
                                                    <StyledTypography level="body-sm">{d.desc}</StyledTypography>
                                                    {d.wikiLink && <Link href={d.wikiLink}>{d.wikiText ? d.wikiText: 'Wiki Link'}</Link>}
                                                </CenterJustify>
                                            </Item>
                                        )
                                    } else if (!hideUnavailable && !completedTasks.includes(d.name)) {
                                        return (
                                            <DisabledItem key={d.name} sx={{
                                                color: '#333',
                                                backgroundColor: '#1e0101',
                                            }}>
                                                <CheckContainer>
                                                    <Checkbox disabled />
                                                </CheckContainer>
                                                <CenterJustify>
                                                    <StyledTypography level="h3" fontSize="xl">{d.name}</StyledTypography>
                                                    <StyledTypography level="body-sm">{d.desc}</StyledTypography>
                                                    {d.wikiLink && <Link href={d.wikiLink} target='_blank'>{d.wikiText ? d.wikiText: 'Wiki Link'}</Link>}
                                                </CenterJustify>
                                            </DisabledItem>
                                        )
                                    } else return <div />
                                })
                            }
                        </AccordionDetails>
                    </StyledAccordion>
                </AccordionGroup>
            )
        })
    }

    if (data || showAll) {
        return (
            <Stack spacing={1}>
                {tasksArray && renderTaskSet(tasksArray)}
            </Stack>
        )
    }

    return (
        <Stack>
            <Typography level="h1">Waiting for player data...</Typography>
        </Stack>
    )
}
