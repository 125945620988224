import {updatePlayer, setPlayerName, updatePlayerQuests} from '../features/player/playerSlice'
export const getPlayerInfo = (playerName) => async (dispatch) => {
    if (playerName !== null && playerName !== undefined) {
        const url = `https://runescape.wiki/cors/m=runemetrics/profile/profile?user=${playerName}&activities=0`
        const response = await fetch(url).then((response) => (response.ok ? response.json() : response.text()))
            .catch((error) => console.error('Something went wrong: ' + error))
            .then((response) => response)
        if (!(response.error === 'NO_PROFILE')) {
            dispatch(setPlayerName(response.name))
            dispatch(updatePlayer(response))
            localStorage.setItem('_soksTools_playerData', JSON.stringify(response))
        } else dispatch(setPlayerName(playerName))
    }

}

export const getPlayerQuests = (playerName) => async (dispatch) => {
    if (playerName !== null && playerName !== undefined) {
        const url = `https://runescape.wiki/cors/m=runemetrics/quests?user=${playerName}`
        const response = await fetch(url).then((response) => (response.ok ? response.json() : response.text()))
            .catch((error) => console.error('Something went wrong: ' + error))
            .then((response) => response)
        dispatch(updatePlayerQuests(response))
        localStorage.setItem('_soksTools_playerQuests', JSON.stringify(response))
    }
}

/*
old in case need to revert
export const getPlayerInfo = (playerName) => async (dispatch) => {
    if(playerName !== null && playerName !== undefined) {
        const url = 'https://corsproxy.io/?' + encodeURIComponent(`https://apps.runescape.com/runemetrics/profile/profile?user=${playerName}`)
        const response = await fetch(url).then((response) => (response.ok ? response.json() : response.text()))
            .catch((error) => console.error('Something went wrong: ' + error))
            .then((response) => response)
        dispatch(setPlayerName(response.name))
        dispatch(updatePlayer(response))
    }

}

export const getPlayerQuests = (playerName) => async (dispatch) => {
    if(playerName !== null && playerName !== undefined) {
        const url = 'https://corsproxy.io/?' + encodeURIComponent(`https://apps.runescape.com/runemetrics/quests?user=${playerName}`)
        const response = await fetch(url).then((response) => (response.ok ? response.json() : response.text()))
            .catch((error) => console.error('Something went wrong: ' + error))
            .then((response) => response)
        dispatch(updatePlayerQuests(response))
    }
}









 */
