import { useRouteError } from 'react-router-dom'
import styled from 'styled-components'

export default function ErrorPage() {
    const error = useRouteError()
    console.error(error)

    return (
        <div>
            <h1>Oopsies tee-hee uwu</h1>
            <p>that page doesn't real</p>
            <ErrorP>
                <i>{error.statusText || error.message}</i>
            </ErrorP>
        </div>
    )
}

const ErrorP = styled.p`
    color: red;
    font-weight: bold;
`