import { createSlice } from '@reduxjs/toolkit'

export const playerSlice = createSlice({
    name: 'player',
    initialState: {
        username: undefined,
        data: undefined,
        quests: undefined,
        hideUnavailable: false,
        hideCompleted: false,
    },
    reducers: {
        setPlayerName: (state, action) => {
            if(action.payload) {
                state.username = action.payload
            }
        },
        updatePlayer: (state, action) => {
            if(action.payload) {
                state.data = action.payload
            }
        },
        updatePlayerQuests: (state, action) => {
            if(action.payload) {
                state.quests = action.payload
            }
        },
        clearUser: (state) => {
            state.username = undefined
            state.data = undefined
            state.quests = undefined
            localStorage.removeItem('_soksTools_playerData')
            localStorage.removeItem('_soksTools_playerQuests')
        },
        toggleUnavailable: (state) => {
            state.hideUnavailable = !state.hideUnavailable
        },
        toggleCompleted: (state) => {
            state.hideCompleted = !state.hideCompleted
        }
    }
})

export const { updatePlayer, setPlayerName, updatePlayerQuests, clearUser, toggleUnavailable, toggleCompleted} = playerSlice.actions

export default playerSlice.reducer
