export const highPrioWeeklies = {
    id: 'weeklyHigh',
    title: 'Weeklies',
    desc: 'Do everything you can to do these things every week. Resets Wednesday 00:00 UTC',
    tasks: [
        {
            name: 'Circus',
            req: null,
            desc: `Complete Balthazar Beauregard's Big Top Bonanza. Rewards experience in magic, ranged, and agility by default. 
            Unlock thieving events with completion of Some Like It Cold, and firemaking events with completion of The Firemaker's Curse.`,
            wikiLink: 'https://runescape.wiki/w/Balthazar_Beauregard%27s_Big_Top_Bonanza',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Penguin Hide and Seek',
            req: null,
            desc: `Find penguins across the world for points that can be spent for xp lamps. Additional penguins unlocked with each quest in the penguin series.`,
            wikiLink: 'https://jq.world60pengs.com/',
            wikiText: 'World 60 tracker website'
        },
        {
            name: 'Tears of Guthix',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `Tears of Guthix`
                    }
                ]
            },
            desc: `Collect the Tears of Guthix from Juna's cave in Lumbridge swamp cave. Rewards can be increased with 80 mining and crafting. Requires completion of the Tears of Guthix quest.`,
            wikiLink: 'https://runescape.wiki/w/Tears_of_Guthix',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Herby Werby',
            req: null,
            desc: `Play the Herby Werby minigame on Anachronia. Rewards lots of herblore experience (or firemaking if you're a psychopath). 
            Only clicking on zyogmites carrying green herbs gives herblore xp. Experience scaling increases every 10 herblore levels starting at 85.`,
            wikiLink: 'https://runescape.wiki/w/Herby_Werby',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Help Meg',
            req: null,
            desc: `Help Meg in your Player Owned Port become a better adventurer. Rewards an experience lamp in a random skill. 
            Does not require level 90 in any ports skills, just enter the portal in Port Sarim. Correct answers can be found on the wiki.`,
            wikiLink: 'https://runescape.wiki/w/Meg',
            wikiText: 'Wiki Link'
        },
    ]
}

export const highPrioMonthlies = {
    id: 'monthlyHigh',
    title: 'Monthlies',
    desc: 'Do everything you can to do these things every month. Resets on the 1st of each month 00:00 UTC',
    tasks: [
        {
            name: 'God Statues',
            req: null,
            desc: `Build God Statues in Taverley, Lumbridge, Canifis, Yanille, and Prifddinas every month. Rewards lots of construction and prayer experience, or slayer experience instead of prayer if you like being wrong. 
            Requires completion of Let Them Eat Pie or manually disabling tutorial mode.`,
            wikiLink: 'https://runescape.wiki/w/God_Statues',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Giant Oyster',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `Beneath Cursed Tides`
                    }
                ]
            },
            desc: `Feed the giant oyster in the sunken tutorial island. Rewards lots of farming and fishing experience, and rewards from the medium to elite treasure trail reward tables. Requires completion of Beneath Cursed Tides.`,
            wikiLink: 'https://runescape.wiki/w/Giant_Oyster',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Troll Invasion',
            req: null,
            desc: `Fight off the troll invasion in Burthorpe. Rewards an experience book that can be used on any skill. Recommend at least 50 in whatever combat style you're using.`,
            wikiLink: 'https://runescape.wiki/w/Troll_Invasion',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Water Filtration System',
            req: {
                dataName: 'skillvalues',
                values: [
                    {
                        id: 22, //construction
                        level: 20,
                    },
                ],
            },
            desc: `Clean out the Water Filtration System in Het's Oasis. Rewards random gems and elder god troves and other stuff. Requires 20 construction to build.`,
            wikiLink: 'https://runescape.wiki/w/Water_filtration_system',
            wikiText: 'Wiki Link'
        },
        {
            name: 'Effigy Incubator',
            req: {
                dataName: ['quests'],
                values: [
                    {
                        questName: `Desperate Measures`
                    }
                ]
            },
            desc: `Play the Effigy Incubator minigame on Anachronia. Rewards funny effigies that are basically just urns on crack. Requires completion of Desperate Measures and level 85 in any of Invention, Crafting, Runecrafting, or Smithing.`,
            wikiLink: 'https://runescape.wiki/w/Effigy_Incubator',
            wikiText: 'Wiki Link'
        },
    ]
}
