import React, {useEffect, useState} from 'react'
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input'
import Button from '@mui/joy/Button'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import {useDispatch, useSelector} from 'react-redux'
import {getPlayerInfo, getPlayerQuests} from '../services'
import {
    CircularProgress,
    Link,
    List,
    ListItem,
    ListItemButton,
    listItemButtonClasses,
    Switch,
    Typography
} from '@mui/joy'
import {
    clearUser,
    setPlayerName, toggleCompleted,
    toggleUnavailable,
    updatePlayer,
    updatePlayerQuests
} from '../features/player/playerSlice'
import {useEffectOnce} from '../hooks/useEffectOnce'
import {ReceiptLong, Task} from '@mui/icons-material'
import styled from 'styled-components'
import {Tasks} from '../pages/Tasks'
import {diaryDaily, highPrioDaily, lowPrioDaily, medPrioDaily, minigameDaily} from '../tasksData/dailies'
import {highPrioMonthlies, highPrioWeeklies} from '../tasksData/weeklies'


const dailiesArray = [highPrioDaily, medPrioDaily, lowPrioDaily, diaryDaily, minigameDaily]
const otherArray = [highPrioWeeklies, highPrioMonthlies]
export default function Root() {

    const [usernameInput, setUsernameInput] = useState(undefined)
    const [loadingUser, setLoadingUser] = useState(false)
    const hideCompleted = useSelector((state) => state.player.hideCompleted)
    const hideUnavailable = useSelector((state) => state.player.hideUnavailable)

    const player = useSelector((state) => state.player)
    const dispatch = useDispatch()

    const handleUserInputChange = (e) => setUsernameInput(e.target.value)

    const handleChangeUser = (e) => {
        e.preventDefault()
        dispatch(clearUser())
    }

    const fetchPlayer = (event) => {
        setLoadingUser(true)
        event && event.preventDefault()
        if(!player.data) {
            dispatch(getPlayerInfo(usernameInput))
        }
        if(!player.quests) {
            dispatch(getPlayerQuests(usernameInput))
        }
    }

    useEffectOnce(() => {
        if (localStorage.getItem('_soksTools_playerData') && localStorage.getItem('_soksTools_playerData') !== null) {
            if (JSON.parse(localStorage.getItem('_soksTools_playerData')).error === 'NO_PROFILE') {
                localStorage.removeItem('_soksTools_playerData')
            } else {
                const data = JSON.parse(localStorage.getItem('_soksTools_playerData'))
                dispatch(setPlayerName(data.name))
                dispatch(updatePlayer(data))
            }
        }
        if (localStorage.getItem('_soksTools_playerQuests') && localStorage.getItem('_soksTools_playerQuests') !== null) {
            if (JSON.parse(localStorage.getItem('_soksTools_playerQuests')).quests.length <= 0) {
                localStorage.removeItem('_soksTools_playerQuests')
                document.cookie = '_soksTools-player=null;Max-Age=0;path=/'
            } else {
                const data = JSON.parse(localStorage.getItem('_soksTools_playerQuests'))
                dispatch(updatePlayerQuests(data))
            }
        }
    })

    useEffect(() => {
        if (player) {
            setLoadingUser(false)
        }
    }, [player])

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                        marginRight: 10,
                    }}>
                    {!player.data && !loadingUser ?
                        <form onSubmit={fetchPlayer}>
                            <Input
                                type='search'
                                size='sm'
                                variant='outlined'
                                placeholder='Enter Username...'
                                startDecorator={<SearchRoundedIcon color='neutral' />}
                                endDecorator={<Button color='neutral' onClick={fetchPlayer}>Submit</Button>}
                                sx={{
                                    alignSelf: 'center',
                                    display: {
                                        xs: 'none',
                                        sm: 'flex',
                                    },
                                }}
                                onChange={handleUserInputChange}
                                value={usernameInput}
                            />
                        </form>
                        : loadingUser ?
                            <CircularProgress color="neutral" />
                            :
                            <PlayerInfoContainer>
                                <Typography level='body-md' sx={{ marginBottom: '4px' }}>
                                    {player.username} <Typography variant="soft">Total: {player.data.totalskill}</Typography>
                                </Typography>
                                <Button
                                    color="danger"
                                    onClick={handleChangeUser}
                                    size="sm"
                                    variant="soft"
                                >
                                    Change User
                                </Button>
                            </PlayerInfoContainer>
                    }
                </Box>
                <Box>

                </Box>
            </Box>
            <BodyContainer>
                <Box sx={{ width: 160, pl: '34px', position: 'fixed', marginTop: '55px',}}>
                    <List size='sm'
                          sx={(theme) => ({
                              // Gatsby colors
                              '--joy-palette-primary-plainColor': '#8a4baf',
                              '--joy-palette-neutral-plainHoverBg': 'transparent',
                              '--joy-palette-neutral-plainActiveBg': 'transparent',
                              '--joy-palette-primary-plainHoverBg': 'transparent',
                              '--joy-palette-primary-plainActiveBg': 'transparent',
                              [theme.getColorSchemeSelector('dark')]: {
                                  '--joy-palette-text-secondary': '#635e69',
                                  '--joy-palette-primary-plainColor': '#d48cff',
                              },
                              '--List-insetStart': '32px',
                              '--ListItem-paddingY': '0px',
                              '--ListItem-paddingRight': '16px',
                              '--ListItem-paddingLeft': '21px',
                              '--ListItem-startActionWidth': '0px',
                              '--ListItem-startActionTranslateX': '-50%',
                              [`& .${listItemButtonClasses.root}`]: {
                                  borderLeftColor: 'divider',
                              },
                              [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
                                  borderLeftColor: 'currentColor',
                              },
                              '& [class*="startAction"]': {
                                  color: 'var(--joy-palette-text-tertiary)',
                              },
                          })}
                    >
                        <ListItem nested>
                            <ListItem component="div" startAction={<ReceiptLong />}>
                                <Typography level="body-xs">
                                    Dailies
                                </Typography>
                            </ListItem>
                            <List sx={{ '--List-gap': '0px' }}>
                                <ListItem>
                                    <ListItemButton component="a" href='#dailyHigh'>High Priority</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component="a" href='#dailyMed'>Med Priority</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component="a" href='#dailyLow'>Low Priority</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component="a" href='#dailyDiary'>Diary</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component="a" href='#dailyMinigame'>Minigame</ListItemButton>
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem nested>
                            <ListItem component="div" startAction={<Task />}>
                                <Typography level="body-xs">
                                    Other
                                </Typography>
                            </ListItem>
                            <List sx={{ '--List-gap': '0px' }}>
                                <ListItem>
                                    <ListItemButton component="a" href='#weeklyHigh'>Weeklies</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component="a" href='#monthlyHigh'>Monthlies</ListItemButton>
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>
                <NavMargin>
                    <FullContainer>
                        <SwitchContainer>
                            <Switch checked={hideUnavailable} onChange={() => dispatch(toggleUnavailable())} />
                            <Typography level='body-sm' sx={{ marginLeft: '8px' }}>Hide Unavailable Tasks</Typography>
                        </SwitchContainer>
                        <SwitchContainer>
                            <Switch checked={hideCompleted} onChange={() => dispatch(toggleCompleted())} />
                            <Typography level='body-sm' sx={{ marginLeft: '8px' }}>Hide Completed Tasks</Typography>
                        </SwitchContainer>
                    </FullContainer>
                    <FullContainer>
                        <DisclaimerText level='body-md'>
                            Disclaimer: Some things that are repeatable tasks aren't listed here because I don't think they are worth doing.
                            A comprehensive list can be found on the wiki <Link href='https://runescape.wiki/w/Repeatable_events' target='_blank' rel="noreferrer">here.</Link>
                            <br />
                            All of these items are prioritized according to my own opinion. If there is something missing here
                            that you think should be added, or something you think is in the wrong tier, let me know. Either Kneesuk or (insert my gim here) in game.
                        </DisclaimerText>
                    </FullContainer>
                    <FullContainer>
                        <TaskContainer>
                            <Tasks hideCompleted hideUnavailable tasksArray={dailiesArray} />
                            <Tasks hideCompleted hideUnavailable tasksArray={otherArray} />
                        </TaskContainer>
                    </FullContainer>
                </NavMargin>
            </BodyContainer>
        </div>

    )
}

const BodyContainer = styled.div`
    display: flex;
`
const NavMargin = styled.div`
    margin-left: 180px;
    display: flex;
    flex-wrap: wrap;
`
const FullContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
`
const SwitchContainer = styled.div`
    margin-left: 65px;
    display: flex;
`
const TaskContainer = styled.div`
    width: 100%;
`
const PlayerInfoContainer = styled.div`
    width: 180px;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    margin-top: 65px;
`
const DisclaimerText = styled(Typography)`
    color: #111;
    margin-left: 65px;
`
