import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import '@fontsource/inter'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Root from './routes/root'
import ErrorPage from './error-page'
import {Tasks} from './pages/Tasks'
import {Provider} from 'react-redux'
import store from './store'
import {CookiesProvider} from 'react-cookie'
import {CssVarsProvider} from '@mui/joy'

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <Tasks />
            }
        ]
    }
])

root.render(
    <CssVarsProvider defaultMode='dark'>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </CookiesProvider>
    </CssVarsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
